import { type LinkDescriptor } from '@remix-run/node';
import riveWASMResource from '@rive-app/canvas/rive.wasm?url';
import * as RiveReactCanvas from '@rive-app/react-canvas';

export function setRiveWasmUrl() {
  RiveReactCanvas.RuntimeLoader.setWasmUrl(riveWASMResource);
}

export const preloadRiveWasmLink: LinkDescriptor = {
  rel: 'preload',
  href: riveWASMResource,
  as: 'fetch',
  crossOrigin: 'anonymous',
};
