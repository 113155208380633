import {
  type ClientLoaderFunctionArgs,
  json,
  redirect,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { type AxiosResponse } from 'axios';
import { useMemo } from 'react';

import { type DtoSingleGamePackResponse } from '@lp-lib/api-service-client/public';

import { useUserAnalytics } from '../analytics/user';
import { UserAccess } from '../components/Access/UserAccess';
import { setRiveWasmUrl } from '../components/GameV2/animation/riveWasm';
import { PlayCursor } from '../components/GameV2/PlayCursor';
import { Playground } from '../components/GameV2/Playground';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { apiService } from '../services/api-service';
import { isForbidden, isNotFound, tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();
setRiveWasmUrl();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Error('expected gamepack id');
  }

  let resp: AxiosResponse<DtoSingleGamePackResponse>;
  try {
    resp = await tokenWithRedirect(
      () =>
        apiService.gamePack.getGamePackById(id, {
          blocks: true,
          games: true,
          progression: 'authenticating-user',
        }),
      action.request.url,
      {
        // for now, require authentication. in the future, we may permit unauthenticated access...
        requireAuthentication: true,
        preferRedirect: 'login',
      }
    );
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }

  if (!resp.data.progression) {
    throw redirect(`/game-packs/${id}/overworld`);
  }

  const gameIdToPlay = action.params.gameId || resp.data.games?.[0].id;
  if (!gameIdToPlay) {
    throw redirect(`/game-packs/${id}/overworld`);
  }

  if (gameIdToPlay) {
    const game = resp.data.games?.find((game) => game.id === gameIdToPlay);
    if (!game || !resp.data.progression.progress?.[game.id]) {
      throw redirect(`/game-packs/${id}/overworld`);
    }
  }

  return {
    gamePackBundle: resp.data,
    gameIdToPlay,
  };
};

function PlaygroundInternal() {
  const { gamePackBundle, gameIdToPlay } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();

  const cursor = useMemo(() => {
    return PlayCursor.FromGamePackResponse(gamePackBundle, gameIdToPlay);
  }, [gameIdToPlay, gamePackBundle]);

  const handleClose = useLiveCallback(() => {
    navigate(`/game-packs/${gamePackBundle.gamePack.id}/overworld`);
  });

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess allowGuests />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Playground
        cursor={cursor}
        onClose={handleClose}
        // return users back to the overworld on continue.
        onMinigameContinue={handleClose}
      />
    </ProvidersList>
  );
}

export const Component = PlaygroundInternal;
