import { type LinksFunction } from '@remix-run/node';

import { preloadRiveWasmLink } from '../../src/components/GameV2/animation/riveWasm';
import { Component } from '../../src/routes/game-packs.$id.play.($gameId).client';
export default Component ?? (() => <></>);
export { clientLoader } from '../../src/routes/game-packs.$id.play.($gameId).client';
export const HydrateFallback = () => <></>;

export const links: LinksFunction = () => {
  return [preloadRiveWasmLink];
};
